module.exports = [{
      plugin: require('../plugins/gatsby-plugin-cro/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"L3UWqZFYa0jF2PtgpEsKod2WtZet4Zvr","devKey":"L3UWqZFYa0jF2PtgpEsKod2WtZet4Zvr"},
    },{
      plugin: require('../../node_modules/@crometrics/gatsby-plugin-optimizely-js/gatsby-browser.js'),
      options: {"plugins":[],"optimizelyId":"12600010354"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VHKGTDRGNH"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T44F8F","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Become a Driver & Deliver with DoorDash | Alternative To Hourly Jobs","short_name":"DoorDash","description":"Drive and deliver with DoorDash and start making money today. Pick your own schedule and use any car or bike. Fast signup, great pay, easy work. Be a Dasher now!","start_url":"/","icon":"src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58ac37e058725e6cd5dafe17f7a94ef0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","en-CA","fr-CA"],"defaultLanguage":"en","siteUrl":"","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
